import * as api from './apiService';

export const GetCalls = async (page, type, from, to, source, destination, services, inbounds, outbounds, users, idTicket, customerCode, vocalOrderOnly, quality, qualityType, cancelToken) => {
    var dict = {
        page: page,
        type: type,
        from: from,
        to: to,
        source: source ? encodeURIComponent(source) : undefined,
        destination: destination ? encodeURIComponent(destination) : undefined,
        services: services,
        inbounds: inbounds,
        outbounds: outbounds,
        users: users,
        idTicket: idTicket,
        customerCode: customerCode,
        quality: quality,
        qualityType: qualityType
    }
    
    return await api.Get(vocalOrderOnly ? "media/vocalorder" : "media/calls", dict, null, cancelToken);
};

export const GetCall = async (calluid, cancelToken) => {
    return await api.Get(`media/calls/${calluid}`, null, null, cancelToken);
};

export const GetServices = async (type, cancelToken) => {
    var dict = {
        type: type
    };
    return await api.Get("services", dict, null, cancelToken);
};

export const GetUsers = async (type, cancelToken) => {
    var dict = {
        type: type
    };
    return await api.Get("users", dict, null, cancelToken);
};

export const GetPrivileges = async (cancelToken) => {
    return await api.Get("media/prv", null, null, cancelToken);
};

export const VerifyQuality = async (calluid, quality, note, cancelToken) => {
    var data = {
        calluid: calluid,
        quality: quality,
        note: note
    };
    return await api.Post("media/verifyquality", data, null, cancelToken);
};

export const DownloadFile = async (kv) => {
    var dict = {
        kv: kv
    };

    return await api.GetWithType("media/download", dict, null, 'arraybuffer', null);
};