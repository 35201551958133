import axios from 'axios';
import './../interceptors/axios.interceptor';
import * as config from './../configs/apiServiceConfig';

const endpoint = config.endpoint;

export const Get = async (path, params, headers, cancelToken) => {
    return await GetWithType(path, params, headers, null, cancelToken);
}
export const GetWithType = async (path, params, headers, responseType, cancelToken) => {
    var query = '';
    var options = {};

    if (typeof responseType !== 'undefined' && responseType !== null) {
        options.responseType = responseType;
    }
    
    if (typeof params !== 'undefined' && params !== null) {
        for (var prop in params) {
            var val = params[prop];
            if (typeof val !== 'undefined' && val !== null && val !== '') {
                var typedVal = val;
                if (val instanceof Date) {
                    typedVal = val.toISOString();
                }
                if (val instanceof Array && val.length === 0) {
                    continue;
                }
                query += `${(query.length == 0 ? '?' : '&')}${prop}=${typedVal}`;
            }
        }
    }

    if (typeof headers !== 'undefined' && headers !== null) {
        options.headers = { ...options.headers, headers };
    }

    if (typeof cancelToken !== 'undefined' && cancelToken !== null) {
        options.cancelToken = cancelToken;
    }

    var url = endpoint + path + query;

    var result = await axios({
        method: "get",
        url: url,
        responseType: options.responseType,
        headers: options.headers,
        cancelToken: options.cancelToken
    });

    return result;
}
export const Post = async (path, data, headers, cancelToken) => {
    var options = {};

    if (typeof headers !== 'undefined' && headers !== null) {
        options.headers = { ...options.headers, headers };
    }

    if (typeof cancelToken !== 'undefined' && cancelToken !== null) {
        options.cancelToken = cancelToken;
    }

    var url = endpoint + path;
    var result = await axios.post(url, data, options);

    return result;
}
export const Put = async (path, data, headers, cancelToken) => {
    var options = {};

    if (typeof headers !== 'undefined' && headers !== null) {
        options.headers = { ...options.headers, headers };
    }

    if (typeof cancelToken !== 'undefined' && cancelToken !== null) {
        options.cancelToken = cancelToken;
    }

    var url = endpoint + path;
    var result = await axios.put(url, data, options);

    return result;
}
export const Patch = async (path, data, headers, cancelToken) => {
    var options = {};

    if (typeof headers !== 'undefined' && headers !== null) {
        options.headers = { ...options.headers, headers };
    }

    if (typeof cancelToken !== 'undefined' && cancelToken !== null) {
        options.cancelToken = cancelToken;
    }

    var url = endpoint + path;
    var result = await axios.patch(url, data, options);

    return result;
}
export const Delete = async (path, params, headers, cancelToken) => {
    var query = '';
    var options = {};

    if (typeof params !== 'undefined' && params !== null) {
        for (var prop in params) {
            var val = params[prop];
            query += `${(query.length == 0 ? '?' : '&')}${prop}=${val}`;
        }
    }

    if (typeof headers !== 'undefined' && headers !== null) {
        options.headers = { ...options.headers, headers };
    }

    if (typeof cancelToken !== 'undefined' && cancelToken !== null) {
        options.cancelToken = cancelToken;
    }

    var url = endpoint + path + query;
    var result = await axios.delete(url, options);

    return result;
}