import React, { useEffect } from 'react';
import { Preload } from 'react-preload';
import ReactTooltip from 'react-tooltip';
import { ToastContainer } from 'react-toastify';

import './Layout.css';
import 'bootstrap/dist/css/bootstrap.css'
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';

import Inbound from '../images/inbound.svg';
import Outbound from '../images/outbound.svg';
import FileExist from '../images/file-exist.svg';
import FileNotExist from '../images/file-not-exist.svg';
import FullDownload from '../images/full-download.svg';
import FullDownloadDenied from '../images/full-download-denied.svg';
import FullDownloadDisabled from '../images/full-download-disabled.svg';
import VocalOrderDownload from '../images/vocalorder-download.svg';
import VocalOrderDownloadDisabled from '../images/vocalorder-download-disabled.svg';
import Answer from '../images/answer.svg';
import TransferTo from '../images/transferTo.svg';
import Transferred from '../images/transferred.svg';
import Recorded from '../images/record.svg';
import LogoConsulcesiTech from '../images/logo_consulcesi_tech_neg.png';
import Logo from '../images/ocmediaservice-logo.svg';
import Assistant from '../images/assistant.svg';
import MissedCall from '../images/missed-call.svg';
import Star from '../images/star.svg';
import StarVerified from '../images/star-quality.svg';
import StarEmpty from '../images/star-empty.svg';
import CallCenter from '../images/ic_callcenter.svg';
import Supervisor from '../images/ic_supervisor.svg';

function Layout(props) {
    return (
        <Preload images={
            [
                Inbound, Outbound, FileExist, FileNotExist,
                FullDownload, FullDownloadDenied, FullDownloadDisabled, VocalOrderDownload,
                VocalOrderDownloadDisabled, Answer, TransferTo, Transferred,
                Recorded, LogoConsulcesiTech, Logo, Assistant, MissedCall, Star, StarVerified,
                StarEmpty, CallCenter, Supervisor
            ]}
        >
            <React.Fragment>
                <div className="content">
                    {props.children}
                </div>
                <ReactTooltip effect="solid" />
                <ToastContainer autoClose={3000} />
            </React.Fragment>
        </Preload>
    );
}

export default Layout;