import React, { useEffect, useState } from 'react';
import { CancelToken } from 'axios';
import * as apiServiceConfig from '../../configs/apiServiceConfig';
import * as dynamicsConfig from '../../configs/dynamicsConfig';
import * as openComm from './../../services/openCommService';
import EventItems from './../../components/EventItems';
import VerifyStarModal from './../../components/VerifyStarModal';
import ReactTooltip from 'react-tooltip';

import LogoConsulcesiTech from '../../images/logo_consulcesi_tech_neg.png';
import Logo from '../../images/ocmediaservice-logo.svg';
import Inbound from '../../images/inbound.svg';
import Outbound from '../../images/outbound.svg';
import FileExist from '../../images/file-exist.svg';
import FileNotExist from '../../images/file-not-exist.svg';
import FullDownload from '../../images/full-download.svg';
import FullDownloadDenied from '../../images/full-download-denied.svg';
import FullDownloadDisabled from '../../images/full-download-disabled.svg';
import VocalOrderDownload from '../../images/vocalorder-download.svg';
import VocalOrderDownloadDisabled from '../../images/vocalorder-download-disabled.svg';
import ErrorIcon from '../../images/error-icon.svg';
import StartFlag from '../../images/start.svg';
import EndFlag from '../../images/end.svg';
import FromToCardImg from '../../images/from-to-img.svg';
import StarEmpty from '../../images/star-empty.svg';
import Star from '../../images/star.svg';
import StarVerified from '../../images/star-quality.svg';
import CallCenter from '../../images/ic_callcenter.svg';
import Supervisor from '../../images/ic_supervisor.svg';
import './index.css'

// window.open("https://localhost:44312/ocpbx-1581099823.832452", "_blank", "toolbar=yes,scrollbars=no,resizable=yes,width=1000,height=700")

let _logger;

function Details(props) {
    _logger = props.logger;
    _logger.trackPageView();

    const [call, useCall] = useState({ item: null, isLoading: false, infoMessage: null });
    const [subItem, useSubItem] = useState({ calluid: null, audioUrl: null });
    const [prv, usePrv] = useState({ loaded: false, vocalOrder: false, full: false, download: false, play: false });
    const [overStars, setOverStars] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [listHeight, useListHeight] = useState({ tot: -1, player: 153 });

    var params = props.match.params;

    const GetPrivileges = async () => {
        let cancel;
        var cancelToken = new CancelToken(c => {
            cancel = c;
        });

        // To cancel the request
        // cancel();

        var response = await openComm.GetPrivileges(cancelToken);

        usePrv(p => {
            if (response.error === null) {
                return { loaded: false, vocalOrder: response.result.vocalOrder, full: response.result.full, download: response.result.download, play: response.result.play }
            } else {
                return p;
            }
        });
    };
    const GetCall = async (calluid) => {
        useCall(p => ({ ...p, item: p.item, isLoading: true }));

        let cancel;
        var cancelToken = new CancelToken(c => {
            cancel = c;
        });

        // To cancel the request
        // cancel();

        var response = await openComm.GetCall(calluid, cancelToken);
        var call = response.error !== null ? null : response.result.media;

        var responseItem = {};
        var subItem = null;
        if (response.error !== null) {
            // Error
            if (response.status === 401 || response.status === 403) {
                responseItem = { item: null, isLoading: false, infoMessage: "You are not authorized to view this page." };
            } else {
                responseItem = { item: null, isLoading: false, infoMessage: "Unknown error." };
            }
        }
        else if (response.status === 200 && !call) {
            // Call w/out vocal-order
            responseItem = { item: null, isLoading: false, infoMessage: "You are not authorized to view this call." };
        }
        else if (response.status === 204 && !call) {
            // Call not found
            responseItem = { item: null, isLoading: false, infoMessage: "Call not found." };
        } else {
            var fullServiceName;
            if (typeof call.services !== 'undefined' && call.services !== null && call.services.length > 0) {
                fullServiceName = call.services[0] + " - ";
            }
            if (call.type.toLocaleLowerCase() === 'inbound' && typeof call.inbounds !== 'undefined' && call.inbounds !== null && call.inbounds.length > 0) {
                fullServiceName += call.inbounds[0];
            }
            if (call.type.toLocaleLowerCase() !== 'inbound' && typeof call.outbound !== 'undefined' && call.outbound !== null) {
                fullServiceName += call.outbound;
            }

            var item = {
                calluid: call.calluid,
                originalstarttime: call.startTime,
                starttime: new Date(call.startTime).toLocaleTimeString(),
                startdate: new Date(call.startTime).toLocaleDateString(),
                endtime: new Date(call.endTime).toLocaleTimeString(),
                type: call.type.toLocaleLowerCase(),
                fullServiceName: fullServiceName,
                idTicket: call.idTicket,
                customerCode: call.customerCode,
                vocalOrder: response.result.vocalOrder,
                from: call.from,
                to: call.to,
                events: call.events,
                fileExist: call.fileExist,
                mediaStreamKv: call.mediaStreamKv,
                mediaFullKv: call.mediaFullKv,
                mediaVocalOrderKvs: call.mediaVocalOrderKvs,
                registroCti: call.registroCti,
                contattoId: call.contattoId
            }

            //subItem = { calluid: call.calluid, audioUrl: `${apiServiceConfig.endpoint}media?kv=${call.mediaStreamKv}` };
            subItem = { calluid: call.calluid, audioUrl: `${apiServiceConfig.endpoint}media?kv=${call.mediaStreamKv}`, eventItems: call.events, qualityOperator: call.qualityOperator, qualityVerified: call.qualityVerified, qualityNote: call.qualityNote };

            responseItem = { item: item, isLoading: false, infoMessage: null };
        }

        useCall(p => ({ ...p, ...responseItem }));
        useSubItem(p => subItem || p);
        usePrv(p => ({ ...p, loaded: true }))
    };

    const listener = function (e) {
        var container = document.getElementsByClassName('audio-info-container')[0];
        if (typeof container !== 'undefined') {
            if (e.deltaY > 0) container.scrollLeft += 50;
            else container.scrollLeft -= 50;
        }
    }
    const OnEventClicked = async (item) => {
        let cSubItem;

        if (item.audioUrl === null && item.time === 0) {
            cSubItem = { calluid: item.calluid, audioUrl: 'none', qualityOperator: item.qualityOperator, qualityVerified: item.qualityVerified, qualityNote: item.qualityNote };
            if (document.getElementsByClassName("collapse").length > 0) {
                CalculateCollapsedHeight(true);
            } else {
                CalculateHeight(null, true);
            }
        }
        else if (item.audioUrl !== null) {
            cSubItem = { calluid: item.calluid, audioUrl: item.audioUrl, qualityOperator: item.qualityOperator, qualityVerified: item.qualityVerified, qualityNote: item.qualityNote };
            if (document.getElementsByClassName("collapse").length > 0) {
                CalculateCollapsedHeight(true);
            } else {
                CalculateHeight(null, true);
            }
        }
        else {
            var player = document.getElementById("player");
            player.currentTime = item.time / 1000;
        }

        useSubItem(p => typeof cSubItem !== 'undefined' ? ({ ...cSubItem, eventItems: p.eventItems }) : p);
    };
    const OnClickDownload = async (e, kvs) => {
        e.stopPropagation();

        if (kvs !== null) {
            for (var i = 0; i < kvs.length; i++) {
                var response = await openComm.DownloadFile(kvs[i]);
                var data = new Blob([response.result], { type: "audio/mpeg" })

                if (data.size !== 0) {
                    var fileName = /attachment\; filename\=(.*)/g.exec(response.headers["content-disposition"])[1];

                    var a = document.createElement('a');
                    var url = window.URL.createObjectURL(data);
                    a.href = url;
                    a.setAttribute("download", fileName);
                    a.target = '_parent';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                }
            }
        }
    };

    const OnClickRegistroCti = async (e, registroId) => {
        e.stopPropagation();

        if (registroId !== null) {
            window.open(dynamicsConfig.CrmUrl + "&etn=clu_registrocti&id=" + registroId + "&newWindow=true&pagetype=entityrecord", "_blank");
        }
    };

    const OnClickContatto = async (e, contattoId) => {
        e.stopPropagation();

        if (contattoId !== null) {
            window.open(dynamicsConfig.CrmUrl + "&etn=contact&id=" + contattoId + "&newWindow=true&pagetype=entityrecord", "_blank");
        }
    };

    const OnVerifyStarClick = async (vote) => {
        if (!subItem.qualityVerified && subItem.qualityOperator) {
            // Open vote popup
            setOverStars(vote);
            setIsOpen(true);
        }
    };
    const OnVerifyStarOver = async (vote, exist) => {
        let update;

        if (!exist) {
            update = { over: true, overStar: vote };
        }

        useSubItem(p => {
            return typeof update !== 'undefined' ? ({ ...update, calluid: p.calluid, audioUrl: p.audioUrl, eventItems: p.eventItems, qualityOperator: p.qualityOperator, qualityVerified: p.qualityVerified, qualityNote: p.qualityNote }) : p;
        });
    };
    const OnVerifyStarLeave = async () => {
        useSubItem(p => {
            return { calluid: p.calluid, audioUrl: p.audioUrl, eventItems: p.eventItems, qualityOperator: p.qualityOperator, qualityVerified: p.qualityVerified, over: false, overStar: null, qualityNote: p.qualityNote };
        });
    };

    const OnCloseModal = async (e) => {
        setIsOpen(false);

        let subItemUpdate;

        if (e && e.confirmed) {
            call.qualityVerified = subItem.stars;
            call.qualityNote = subItem.note;

            subItemUpdate = { qualityVerified: e.stars, over: false, overStar: null, qualityNote: e.note };
        }

        useSubItem(p => {
            return typeof subItemUpdate !== 'undefined' ? ({ ...subItemUpdate, calluid: p.calluid, audioUrl: p.audioUrl, eventItems: p.eventItems, qualityOperator: p.qualityOperator }) : p;
        });
    };

    const CalculateHeight = async (formHeight, playerOpened, windowHeight) => {
        var navHeight = listHeight.nav || document.getElementsByTagName("nav")[0].offsetHeight;

        if (typeof windowHeight === 'undefined' || windowHeight === null) {
            windowHeight = listHeight.window || window.innerHeight;
        }

        if (typeof formHeight === 'undefined' || formHeight === null) {
            formHeight = listHeight.form;
        }

        var totHeight = windowHeight - navHeight - formHeight - 41;

        var playerInfoHeight = listHeight.player;
        if (playerOpened) {
            playerInfoHeight = typeof (document.getElementsByClassName("audio-container")[0]) === 'undefined' ? listHeight.player : document.getElementsByClassName("audio-container")[0].offsetHeight;
            totHeight -= playerInfoHeight;
        }

        if (totHeight.toString() === "NaN") {
            totHeight = 500;
        }

        // Save calculated height of virtualized-list
        useListHeight({ window: windowHeight, nav: navHeight, form: formHeight, player: playerInfoHeight, tot: totHeight });
    };
    const CalculateCollapsedHeight = async (playerOpened, windowHeight) => {
        var navHeight = listHeight.nav || document.getElementsByTagName("nav")[0].offsetHeight;

        if (typeof windowHeight === 'undefined' || windowHeight === null) {
            windowHeight = listHeight.window || window.innerHeight;
        }

        var formHeight = 0;
        var totHeight = windowHeight - navHeight - formHeight - 41;

        var playerInfoHeight = listHeight.player;
        if (playerOpened) {
            playerInfoHeight = typeof (document.getElementsByClassName("audio-container")[0]) === 'undefined' ? listHeight.player : document.getElementsByClassName("audio-container")[0].offsetHeight;
            totHeight -= playerInfoHeight;
        }

        if (totHeight.toString() === "NaN") {
            totHeight = 500;
        }

        // Save calculated height of virtualized-list
        useListHeight(p => ({ window: windowHeight, nav: navHeight, form: p.form, player: playerInfoHeight, tot: totHeight }));
    };

    useEffect(function () {
        // On loading, get user privileges
        GetPrivileges();
        GetCall(params.calluid);

        window.addEventListener('wheel', listener);
    }, [null]);

    return (
        <React.Fragment>
            <nav className="navbar navbar-light ap-navbar">
                <div className="row w-100">
                    <div className="col-6 text-left">
                        <a className="navbar-brand" href="#">
                            <img src={Logo} width="170" height="30" className="mediaservice-logo d-inline-block align-top" alt="" />
                        </a>
                    </div>
                    <div className="col-6 text-right">
                        <img src={LogoConsulcesiTech} width="75" height="30" className="d-inline-block align-top" alt="" />
                    </div>
                </div>
                <div className="row loader w-100">
                    <div className={prv.loaded ? "bar stop" : "bar"}>
                    </div>
                </div>
            </nav>
            {
                call.infoMessage !== null ?
                    <div className="error-container mt-5">
                        <div className="mb-4"><img src={ErrorIcon} width="90" /></div>
                        <span><h4 className="text-danger">{call.infoMessage}</h4></span>
                    </div>
                    :
                    prv.loaded && call.item !== null ?
                        <React.Fragment>
                            <div className="container">
                                <div className="row mt-5">

                                    <div className="col-12">
                                        <h3 className="mb-5">Call Details</h3>
                                        {/* START & END */}
                                        <div className="row">
                                            <div className="col-2 text-center">
                                                <div className="mb-1"><img src={StartFlag} width="30" /></div>
                                                <div><b className="badge badge-pill badge-success">Start Time</b></div>
                                                <div><b>{call.item.startdate}<br />{call.item.starttime}</b></div>
                                            </div>
                                            <div className="col-8 dashed-line text-center">
                                                <div className="in-out-icon"><img src={call.item.type.toLocaleLowerCase() === 'inbound' ? Inbound : Outbound} className="mediaservice-list-icon" data-tip={call.item.type.toLocaleLowerCase() === 'inbound' ? "Inbound call" : "Outbound call"} /></div>
                                                <div className="in-out-text">{call.item.type.toLocaleLowerCase()}</div>
                                            </div>
                                            <div className="col-2 text-center">
                                                <div className="mb-1"><img src={EndFlag} width="30" /></div>
                                                <div><b className="badge badge-pill badge-danger">End Time</b></div>
                                                <div><b>{call.item.startdate}<br />{call.item.endtime}</b></div>
                                            </div>
                                        </div>
                                        {/* OTHER DATA */}
                                        <div className="row mt-5">
                                            <div className="col-3 text-center">
                                                <div className="card text-center border-radius-card min-height pt-3">
                                                    <img className="card-img-top img-45 mt-4" src={FromToCardImg} width="45" alt="" />
                                                    <div className="card-body text-left">
                                                        <p className="card-text text-left"><b>From:</b> {call.item.from}</p>
                                                        <p className="card-text text-left"><b>To:</b> {call.item.to}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 text-center">
                                                <div className="card text-center border-radius-card min-height">
                                                    <div className="card-body text-left">
                                                        <h5 className="card-title mb-3 lh-45 text-center"><b>{call.item.fullServiceName}</b></h5>
                                                        {
                                                            call.item.registroCti ?
                                                                <p className="card-text text-left"><b>Id ticket:</b> <div className="dyn_link inline_link" onClick={(e) => OnClickRegistroCti(e, call.item.registroCti)}>CTI</div></p>
                                                                :
                                                                <p className="card-text text-left"><b>Id ticket:</b> {call.item.idTicket}</p>
                                                        }
                                                        {
                                                            call.item.contattoId ?
                                                                <p className="card-text text-left"><b>Customer code:</b> <div className="dyn_link inline_link" onClick={(e) => OnClickContatto(e, call.item.contattoId)}>{call.item.customerCode}</div></p>
                                                                :
                                                                <p className="card-text text-left"><b>Customer code:</b> {call.item.customerCode}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3 text-center">
                                                <div className="card text-center border-radius-card min-height pt-3">
                                                    <div className="card-body">
                                                        <div className="row d-flex justify-content-center">
                                                            <div className="col-4">
                                                                <b>File</b>
                                                            </div>
                                                            {
                                                                prv.full && prv.download ?
                                                                    <div className="col-4">
                                                                        <b>Full</b>
                                                                    </div>
                                                                    :
                                                                    <React.Fragment />
                                                            }
                                                            <div className="col-4">
                                                                <b>VO</b>
                                                            </div>
                                                        </div>
                                                        <div className="row d-flex justify-content-center">
                                                            <div className="col-4 pt-1">
                                                                <img src={call.item.fileExist ? FileExist : FileNotExist} className="img-40" />
                                                            </div>
                                                            {
                                                                prv.full && prv.download ?
                                                                    <div className="col-4 pt-1">
                                                                        <img src={call.item.vocalOrder ? FullDownloadDenied : call.item.mediaFullKv !== null ? FullDownload : FullDownloadDisabled} onClick={(e) => OnClickDownload(e, call.item.mediaFullKv !== null ? [call.item.mediaFullKv] : null)} className={(call.item.vocalOrder || call.item.mediaFullKv === null) ? "img-40 disabled" : "img-40 cursor-pointer"} />
                                                                    </div>
                                                                    :
                                                                    <React.Fragment />
                                                            }
                                                            <div className="col-4 pt-1">
                                                                {
                                                                    prv.download ?
                                                                        <img src={call.item.mediaVocalOrderKvs.length > 0 ? VocalOrderDownload : VocalOrderDownloadDisabled} onClick={(e) => OnClickDownload(e, call.item.mediaVocalOrderKvs)} className={call.item.mediaVocalOrderKvs.length > 0 ? "img-40 cursor-pointer" : "img-40 disabled"} />
                                                                        :
                                                                        <React.Fragment />
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {
                                    subItem.audioUrl !== null ?
                                        <React.Fragment>
                                            <div className="audio-container">
                                                <div className="audio-info-container">
                                                    <div className="audio-info">
                                                        <EventItems calluid={call.item.calluid} events={call.item.events} vocalOrder={call.item.vocalOrder} starttime={call.item.originalstarttime} onEventClicked={OnEventClicked} logger={_logger} />
                                                    </div>
                                                </div>
                                                {
                                                    prv.play ?
                                                        <div className="row">
                                                            <audio className="col-md-11 p-0" id="player" src={subItem.audioUrl} controls controlsList="nodownload" />
                                                            <div className="col-md-1 p-0 stars-event-container">
                                                                <div className="star-items">
                                                                    <div>
                                                                        <img src={subItem.qualityOperator > 0 ? Star : StarEmpty} className="media-star-icon" />
                                                                        <img src={subItem.qualityOperator > 1 ? Star : StarEmpty} className="media-star-icon" />
                                                                        <img src={subItem.qualityOperator > 2 ? Star : StarEmpty} className="media-star-icon" />
                                                                        <img src={subItem.qualityOperator > 3 ? Star : StarEmpty} className="media-star-icon" />
                                                                    </div>
                                                                    <div>
                                                                        <img src={CallCenter} className="media-star-icon" />
                                                                    </div>
                                                                </div>
                                                                <div data-tip data-for={`tooltip-event-${subItem.calluid}`} className="star-items">
                                                                    {
                                                                        prv.full ?
                                                                            <div>
                                                                                <img src={(subItem.over && subItem.overStar > 0) ? StarVerified : subItem.qualityVerified > 0 ? StarVerified : StarEmpty} onMouseLeave={() => OnVerifyStarLeave()} onMouseOver={() => OnVerifyStarOver(1, (subItem.qualityVerified !== null || subItem.qualityOperator === null))} onClick={() => OnVerifyStarClick(1)} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 1) ? StarVerified : subItem.qualityVerified > 1 ? StarVerified : StarEmpty} onMouseLeave={() => OnVerifyStarLeave()} onMouseOver={() => OnVerifyStarOver(2, (subItem.qualityVerified !== null || subItem.qualityOperator === null))} onClick={() => OnVerifyStarClick(2)} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 2) ? StarVerified : subItem.qualityVerified > 2 ? StarVerified : StarEmpty} onMouseLeave={() => OnVerifyStarLeave()} onMouseOver={() => OnVerifyStarOver(3, (subItem.qualityVerified !== null || subItem.qualityOperator === null))} onClick={() => OnVerifyStarClick(3)} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 3) ? StarVerified : subItem.qualityVerified > 3 ? StarVerified : StarEmpty} onMouseLeave={() => OnVerifyStarLeave()} onMouseOver={() => OnVerifyStarOver(4, (subItem.qualityVerified !== null || subItem.qualityOperator === null))} onClick={() => OnVerifyStarClick(4)} className="media-star-icon" />
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <img src={(subItem.over && subItem.overStar > 0) ? StarVerified : subItem.qualityVerified > 0 ? StarVerified : StarEmpty} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 1) ? StarVerified : subItem.qualityVerified > 1 ? StarVerified : StarEmpty} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 2) ? StarVerified : subItem.qualityVerified > 2 ? StarVerified : StarEmpty} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 3) ? StarVerified : subItem.qualityVerified > 3 ? StarVerified : StarEmpty} className="media-star-icon" />
                                                                            </div>
                                                                    }
                                                                    <div>
                                                                        <img src={Supervisor} className="media-star-icon" />
                                                                    </div>
                                                                </div>
                                                                <div className={(!subItem.over && subItem.qualityVerified !== null) ? null : 'hidden'}>
                                                                    <ReactTooltip className="tooltip-event" id={`tooltip-event-${subItem.calluid}`} effect="solid">
                                                                        {typeof subItem.qualityNote !== 'undefined' && subItem.qualityNote !== null && subItem.qualityNote !== '' ? subItem.qualityNote : "Nessuna nota disponibile."}
                                                                    </ ReactTooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <React.Fragment />
                                                }
                                            </div>
                                            <VerifyStarModal calluid={call.item.calluid} qualityOperator={subItem.qualityOperator} qualityVerified={overStars} qualityNote={subItem.qualityNote} modalIsOpen={modalIsOpen} closeModal={OnCloseModal} />
                                        </React.Fragment>
                                        :
                                        null
                                }
                            </div>
                        </ React.Fragment>
                        :
                        <React.Fragment />
            }
        </React.Fragment>
    )
}

export default Details;