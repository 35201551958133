import React, { useState } from 'react';
import Moment from 'react-moment';
import ReactTooltip from 'react-tooltip';
import * as apiServiceConfig from '../configs/apiServiceConfig';
import './EventItems.css'
import Assistant from '../images/assistant.svg';
import Answer from '../images/answer.svg';
import TransferTo from '../images/transferTo.svg';
import Transferred from '../images/transferred.svg';
import Recorded from '../images/record.svg';
import MissedCall from '../images/missed-call.svg';

let _logger;

function EventItems(props) {
    _logger = props.logger;

    var eventItems = props.events.map((item, index) => {
        var icon;
        var tip = "";
        var key = `${props.calluid}-${item.type}-${index}-`;
        var label = <Moment format="HH:mm:ss">{item.date}</Moment>;
        var onClick = () => OnEventClicked({ url: props.vocalOrder ? `${apiServiceConfig.endpoint}media?kv=${item.kv}` : null, time: new Date(item.date) - new Date(props.starttime) });
        switch (item.type) {
            case "Operator":
                icon = Assistant;
                tip = "Operator";
                key += item.operatorFullName;
                label = item.operatorFullName;
                onClick = () => { };
                break;
            case 'CallTransfer':
                icon = TransferTo;
                key += item.date;
                tip = "Transfer to operator";
                break;
            case 'DialEnd':
                icon = Answer;
                key += item.date;
                tip = "Answer call";
                break;
            case 'AttendedTransfer':
                icon = Transferred;
                key += item.date;
                tip = "Transfer completed";
                break;
            case 'VocalOrder':
                icon = Recorded;
                key += item.date;
                tip = "Vocal order";
                break;
            case 'MissedCall':
                icon = MissedCall;
                tip = "Missed Call";
                key += item.operatorFullName;
                label = item.operatorFullName;
                onClick = () => { };
                break;
            case 'NoAnswer':
                icon = MissedCall;
                tip = "No Answer";
                key += item.operatorFullName;
                label = item.operatorFullName;
                onClick = () => { };
                break;
            default:
                icon = Answer;
                tip = "";
        }

        return (
            <div key={key} className="event text-center" onClick={onClick}>
                <div>
                    <img src={icon} className="mediaservice-list-icon-big mediaservice-list-icon-centered" data-tip data-for={`tooltip-event-${key}`} />
                </div>
                <div className="mediaservice-list-icon-time">{label}</div>
                <ReactTooltip id={`tooltip-event-${key}`} effect="solid">
                    {tip}
                </ ReactTooltip>
            </div>
        )
    });

    const OnEventClicked = (item) => {
        props.onEventClicked({ calluid: props.calluid, audioUrl: item.url, time: item.time });
    };

    return (
        <React.Fragment>
            {eventItems}
        </React.Fragment>
    );
}

export default EventItems;