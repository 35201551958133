export const tenant = window.config.Tenant;
// fdc87d61-51e9-45a3-97d1-c237078e49a9 -> originale
// a98222cd-c538-4fa4-bf0e-149c2d92c70d -> debug
export const redirectUri = window.config.RedirectUri;

export const clientId = window.config.ClientId;
// export const redirectUri = 'https://ocmediahub.lab.consulcesi.local/.auth/login/aad/callback';

export const endpoints = {
    api: window.config.ClientId,
};
export const cacheLocation = window.config.CacheLocation;