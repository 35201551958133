import React from 'react';
import { Route } from 'react-router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import * as apiServiceConfig from './configs/apiServiceConfig';

import Layout from './pages/Layout';
import Home from './pages/home/index';
import Details from './pages/details/index';

const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: apiServiceConfig.instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

appInsights.loadAppInsights();
appInsights.trackPageView();

function App () {
    return (
        <Layout>
            <Route path='/' exact render={(p) => <Home {...p} logger={appInsights}  />} />
            <Route path='/:calluid' exact render={(p) => <Details {...p} logger={appInsights} />} />
        </Layout>
    )
};

export default withAITracking(reactPlugin, App);