import React, { useState } from 'react';
import { CancelToken } from 'axios';
import Modal from 'react-modal';
import * as openComm from '../services/openCommService';
import Star from '../images/star.svg';
import StarVerified from '../images/star-quality.svg';
import StarEmpty from '../images/star-empty.svg';
import './VerifyStarModal.css'

let _logger;

function VerifyStarModal(props) {
    _logger = props.logger;

    const [txtNote, setTxtNote] = useState('');

    const OnCancel = async () => {
        props.closeModal();
    };

    const OnConfirm = async () => {
        let cancel;
        var cancelToken = new CancelToken(c => {
            cancel = c;
        });

        // To cancel the request
        // cancel();

        var result = await openComm.VerifyQuality(props.calluid, props.qualityVerified, txtNote, cancelToken)
        if (result && result.status === 200) {
            props.closeModal({ confirmed: true, stars: props.qualityVerified, note: txtNote });
        }
        else {
            props.closeModal();
        }

        setTxtNote('');
    };

    const HandleOnChange = async (e) => {
        setTxtNote(e.currentTarget.value ?? '');
    };

    return (
        <React.Fragment>
            <Modal
                isOpen={props.modalIsOpen}
                onRequestClose={props.closeModal}
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>Riepilogo</h2>
                <div>Sei sicuro di voler inviare la seguente valutazione?</div>
                <br />
                <span>Valutazione dell'operatore</span>
                <div>
                    <img src={props.qualityOperator > 0 ? Star : StarEmpty} className="media-star-icon big" />
                    <img src={props.qualityOperator > 1 ? Star : StarEmpty} className="media-star-icon big" />
                    <img src={props.qualityOperator > 2 ? Star : StarEmpty} className="media-star-icon big" />
                    <img src={props.qualityOperator > 3 ? Star : StarEmpty} className="media-star-icon big" />
                </div>
                <br />
                <span>Valutazione di verifica</span>
                <div>
                    <img src={props.qualityVerified > 0 ? StarVerified : StarEmpty} className="media-star-icon big" />
                    <img src={props.qualityVerified > 1 ? StarVerified : StarEmpty} className="media-star-icon big" />
                    <img src={props.qualityVerified > 2 ? StarVerified : StarEmpty} className="media-star-icon big" />
                    <img src={props.qualityVerified > 3 ? StarVerified : StarEmpty} className="media-star-icon big" />
                </div>
                <br />
                <span>Note sulla Valutazione</span>
                <div>
                    <textarea className="star-text-note" placeholder="inserisci qui una nota." onChange={(event) => HandleOnChange(event)} value={txtNote} />
                </div>
                <div className="row star-form-container">
                    <div className="col-md-6 star-container-button">
                        <button id="bnFormCancelVote" className="btn btn-secondary star-form-button" onClick={OnCancel}>ANNULLA</button>
                    </div>
                    <div className="col-md-6 star-container-button">
                        <button id="bnFormSendVote" className="btn btn-primary star-form-button" onClick={OnConfirm}>INVIA</button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default VerifyStarModal;