import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip'
import shouldUpdate from 'recompose/shouldUpdate';
import * as apiServiceConfig from '../configs/apiServiceConfig';
import * as dynamicsConfig from '../configs/dynamicsConfig';
import * as openComm from '../services/openCommService';
import './ListItem.css'
import Inbound from '../images/inbound.svg';
import Outbound from '../images/outbound.svg';
import FileExist from '../images/file-exist.svg';
import FileNotExist from '../images/file-not-exist.svg';
import FullDownload from '../images/full-download.svg';
import FullDownloadDenied from '../images/full-download-denied.svg';
import FullDownloadDisabled from '../images/full-download-disabled.svg';
import VocalOrderDownload from '../images/vocalorder-download.svg';
import VocalOrderDownloadDisabled from '../images/vocalorder-download-disabled.svg';
import Star from '../images/star.svg';
import StarVerified from '../images/star-quality.svg';
import StarEmpty from '../images/star-empty.svg';
import EventItems from './EventItems';
import { toast } from 'react-toastify';
import { DateTime } from "luxon";

let _logger;
let _toasts = [];

function ListItem(props) {
    _logger = props.logger;

    var eventItems = <EventItems calluid={props.calluid} events={props.events} vocalOrder={props.vocalOrder} starttime={props.starttime} onEventClicked={props.onEventClicked} />;
    var qualityValue = props.qualityVerified == null ? props.qualityOperator : props.qualityVerified;
    var qualityType = props.qualityVerified == null ? 'o' : 'v';

    const OnMediaClicked = (url) => {
        props.refreshEvents(eventItems);
        props.onEventClicked({ calluid: props.calluid, audioUrl: url, time: 0, qualityOperator: props.qualityOperator, qualityVerified: props.qualityVerified, qualityNote: props.qualityNote });
    };

    const OnClickDownload = async (e, kvs) => {
        e.stopPropagation();

        if (kvs === null || kvs.length === 0) {
            return;
        }

        var id = toast.info("Inizializzazione del download...", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 10000,
            hideProgressBar: true,
            className: 'dark-toast'
        });
        _toasts.push(id);

        if (kvs !== null) {
            for (var i = 0; i < kvs.length; i++) {
                var response = await openComm.DownloadFile(kvs[i]);

                setTimeout(function () {
                    if (_toasts.length > 0) {
                        toast.dismiss(_toasts[0]);
                        _toasts.shift();
                    }
                }, 1500);

                var data = new Blob([response.result], { type: "audio/mpeg" });

                if (response.status === 204 || data.length === 0) {
                    toast.error("Il file non è stato trovato!", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 4000,
                    });
                    return;
                }

                toast.success("Inizializzo download!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });

                var fileName = /attachment\; filename\=(.*)/g.exec(response.headers["content-disposition"])[1];

                // Convert UTC time to LOCAL time
                var regex = /(.*)_([0-9]{1,2}\-[0-9]{1,2}\-[0-9]{4}\_[0-9]{1,2}\-[0-9]{1,2}\-[0-9]{1,2}).mp3/g.exec(fileName);
                var regexCustomVO = /(.*)\_([0-9]{1,2}\-[0-9]{1,2}\-[0-9]{4}\_[0-9]{1,2}\-[0-9]{1,2}\-[0-9]{1,2})\_([0-9]{1,2})/g.exec(fileName);
                if (regex) {
                    var calltype = regex[1];
                    var dt = DateTime.fromFormat(regex[2], 'dd-MM-yyyy_HH-mm-ss', { zone: 'utc' })
                    var local = dt.toLocal().toFormat('dd-MM-yyyy_HH-mm-ss');

                    fileName = `${calltype}_${local}.mp3`;
                }
                else if (regexCustomVO) {
                    var customerCode = regexCustomVO[1];
                    var dt = DateTime.fromFormat(regexCustomVO[2], 'dd-MM-yyyy_HH-mm-ss', { zone: 'utc' })
                    var local = dt.toLocal().toFormat('dd-MM-yyyy_HH-mm-ss');
                    var counter = regexCustomVO[3];

                    fileName = `${customerCode}_${local}_${counter}.mp3`;
                }

                var a = document.createElement('a');
                var url = window.URL.createObjectURL(data);
                a.href = url;
                a.setAttribute("download", fileName);
                a.target = '_parent';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            }
        }
    };

    const OnClickRegistroCti = async (e, registroId) => {
        e.stopPropagation();

        if (registroId !== null) {
            window.open(dynamicsConfig.CrmUrl + "&etn=clu_registrocti&id=" + registroId + "&newWindow=true&pagetype=entityrecord", "_blank");
        }
    };

    const OnClickContatto = async (e, contattoId) => {
        e.stopPropagation();

        if (contattoId !== null) {
            window.open(dynamicsConfig.CrmUrl + "&etn=contact&id=" + contattoId + "&newWindow=true&pagetype=entityrecord", "_blank");
        }
    };

    var fullServiceName;
    if (typeof props.services !== 'undefined' && props.services !== null && props.services.length > 0) {
        fullServiceName = props.services[0];
    }
    else {
        fullServiceName = "Unknown";
    }
    if (props.type.toLocaleLowerCase() === 'inbound' && typeof props.inbounds !== 'undefined' && props.inbounds !== null && props.inbounds.length > 0) {
        var inbound = props.inbounds[0];
        if (inbound) {
            fullServiceName += " - " + props.inbounds[0];
        }
    }
    if (props.type.toLocaleLowerCase() !== 'inbound' && typeof props.outbound !== 'undefined' && props.outbound !== null && props.outbound !== '') {
        fullServiceName += " - " + props.outbound;
    }

    // Used to fix problem w/ privileges
    var col = 0;
    var columns = props.columns;

    return (
        <React.Fragment>
            <div className={props.className} style={props.style} onClick={() => OnMediaClicked(props.mediaStreamKv !== null ? `${apiServiceConfig.endpoint}media?kv=${props.mediaStreamKv}` : null)}>
                <div className={columns[col].props.className} style={columns[col].props.style}>{new Date(props.starttime).toLocaleDateString()}</div>
                <div className={columns[++col].props.className} style={columns[col].props.style}>{new Date(props.starttime).toLocaleTimeString()}</div>
                <div className={columns[++col].props.className} style={columns[col].props.style}>{new Date(props.endtime).toLocaleTimeString()}</div>
                <div className={columns[++col].props.className} style={columns[col].props.style}>
                    <img src={props.type.toLocaleLowerCase() === 'inbound' ? Inbound : Outbound} className="mediaservice-list-icon" data-tip={props.type.toLocaleLowerCase() === 'inbound' ? "Inbound call" : "Outbound call"} />
                </div>
                <div className={columns[++col].props.className} style={columns[col].props.style}>{fullServiceName}</div>
                {
                    props.registroCti ?
                        <div className={columns[++col].props.className + " dyn_link"} style={columns[col].props.style} onClick={(e) => OnClickRegistroCti(e, props.registroCti)}>CTI</div>
                        :
                        <div className={columns[++col].props.className} style={columns[col].props.style}>{props.idTicket}</div>
                }
                {props.contattoId ?
                    <div className={columns[++col].props.className + " dyn_link"} style={columns[col].props.style} onClick={(e) => OnClickContatto(e, props.contattoId)}>{props.customerCode}</div>
                    :
                    <div className={columns[++col].props.className} style={columns[col].props.style}>{props.customerCode}</div>
                }
                <div className={columns[++col].props.className} style={columns[col].props.style}>{props.from}</div>
                <div className={columns[++col].props.className} style={columns[col].props.style}>{props.to}</div>
                <div className={columns[++col].props.className} style={columns[col].props.style}>
                    <img src={qualityValue > 0 ? qualityType == 'o' ? Star : StarVerified : StarEmpty} className="media-star-icon" />
                    <img src={qualityValue > 1 ? qualityType == 'o' ? Star : StarVerified : StarEmpty} className="media-star-icon" />
                    <img src={qualityValue > 2 ? qualityType == 'o' ? Star : StarVerified : StarEmpty} className="media-star-icon" />
                    <img src={qualityValue > 3 ? qualityType == 'o' ? Star : StarVerified : StarEmpty} className="media-star-icon" />
                </div>
                <div className={columns[++col].props.className} style={columns[col].props.style} data-tip={props.fileExist ? "File exist" : "File not found"}>
                    <img src={props.fileExist ? FileExist : FileNotExist} className="mediaservice-list-icon" />
                </div>
                {
                    props.prv.full && props.prv.download ?
                        <div className={columns[++col].props.className} style={columns[col].props.style} data-tip={props.mediaFullKv !== null ? "Full download" : 'Download not available'}>
                            <img src={props.vocalOrder ? FullDownloadDenied : props.mediaFullKv !== null ? FullDownload : FullDownloadDisabled} onClick={(e) => OnClickDownload(e, props.mediaFullKv !== null ? [props.mediaFullKv] : null)} className={(props.vocalOrder || props.mediaFullKv === null) ? "mediaservice-list-icon disabled" : "mediaservice-list-icon"} />
                        </div>
                        :
                        <React.Fragment />
                }
                {
                    props.prv.vocalOrder && props.prv.download ?
                        <div className={columns[++col].props.className} style={columns[col].props.style} data-tip={props.mediaVocalOrderKvs.length > 0 ? "Vocal order download" : 'Download not available'}>
                            <img src={props.mediaVocalOrderKvs.length > 0 ? VocalOrderDownload : VocalOrderDownloadDisabled} onClick={(e) => OnClickDownload(e, props.mediaVocalOrderKvs)} className={props.mediaVocalOrderKvs.length > 0 ? "mediaservice-list-icon" : "mediaservice-list-icon disabled"} />
                        </div>
                        :
                        <React.Fragment />
                }
            </div>
        </React.Fragment>
    )
}

const checkPropsChange = (props, nextProps) => {
    return (nextProps.calluid !== props.calluid || nextProps.prv !== props.prv || nextProps.className !== props.className);
};

export default shouldUpdate(checkPropsChange)(ListItem)