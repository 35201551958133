import axios from 'axios';
import * as oauthService from './../services/oauthService';
import * as apiServiceConfig from './../configs/apiServiceConfig';
import * as status from 'http-status-codes';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: apiServiceConfig.instrumentationKey
    }
});
appInsights.loadAppInsights();

async function requestHandler(request) {
    var token = oauthService.GetCachedToken();
    if (typeof token === 'undefined' || token === null) {
        var token = await oauthService.AdalGetToken();
    }

    // Attach token to all requests (any: method)
    request.headers.common = { ...request.headers.common, Authorization: `Bearer ${token}` }

    return request;
}

async function responseHandler(response) {
    if (typeof response === 'undefined') {
        appInsights.trackDependencyData('client maybe offline or server unreachable [request error]');
        return {
            headers: [],
            result: null,
            error: 'client maybe offline or server unreachable [request error]',
            status: null
        }
    }
    else if (response.status === status.OK || response.status === status.NO_CONTENT) {
        appInsights.trackDependencyData(response);
        return {
            headers: response.headers,
            result: response.data,
            error: null,
            status: response.status
        };
    }
    else if (response.status === status.UNAUTHORIZED) {
        appInsights.trackDependencyData(response);
        //TODO: add unauth retry
        return {
            headers: response.headers,
            result: null,
            error: '401',
            status: response.status
        };
    }

    appInsights.trackDependencyData(response);
    return {
        headers: response.headers,
        result: null,
        error: {
            status: response.status,
            data: response.data
        },
        status: response.status
    };
}

// Add global interceptor on request
axios.interceptors.request.use(
    async r => await requestHandler(r),
    async e => await responseHandler()
);

// Add global interceptor on response
axios.interceptors.response.use(
    async r => await responseHandler(r),
    async e => await responseHandler(e.response)
);