import React, { useEffect, useState } from 'react';
import { CancelToken } from 'axios';
import uuidv4 from 'uuid/v4';
import ReactTooltip from 'react-tooltip';
import VerifyStarModal from '../../components/VerifyStarModal';
import * as openComm from './../../services/openCommService';
import FilterForm from '../../components/FilterForm';
import CallsList from '../../components/CallsList';
import LogoConsulcesiTech from '../../images/logo_consulcesi_tech_neg.png';
import Logo from '../../images/ocmediaservice-logo.svg';
import ErrorIcon from '../../images/error-icon.svg';
import Collapse from '../../images/collapse.png';
import Star from '../../images/star.svg';
import StarVerified from '../../images/star-quality.svg';
import StarEmpty from '../../images/star-empty.svg';
import CallCenter from '../../images/ic_callcenter.svg';
import Supervisor from '../../images/ic_supervisor.svg';
import './index.css';

let _logger;
let _cancelRequests = [];

function Home(props) {
    _logger = props.logger;
    _logger.trackPageView();

    const [calls, useCalls] = useState({ items: [], isLoading: false, hasMore: true, currentPage: 0, vocalOrder: true, reqId: uuidv4(), total: 0 });
    const [subItem, useSubItem] = useState({ calluid: null, audioUrl: null, eventItems: [] });
    const [prv, usePrv] = useState({ loaded: false, vocalOrder: false, full: false, download: false, play: false });
    const [listHeight, useListHeight] = useState({ tot: -1, player: 153 });
    const [selected, useSelected] = useState(null);
    const [gFilters, useFilters] = useState();
    const [bCollapse, useCollapse] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [overStars, setOverStars] = useState(null);

    const GetPrivileges = async () => {
        let cancel;
        var cancelToken = new CancelToken(c => {
            cancel = c;
        });

        // To cancel the request
        // cancel();

        var response = await openComm.GetPrivileges(cancelToken);

        usePrv(p => {
            if (response.error === null) {
                return { loaded: true, vocalOrder: response.result.vocalOrder, full: response.result.full, download: response.result.download, play: response.result.play }
            } else {
                return p;
            }
        });
    };
    const GetCalls = async (page, { type, from, to, source, destination, services, inbounds, outbounds, users, idTicket, customerCode, vocalOrderOnly, quality, qualityType }) => {
        useCalls(p => {
            return { items: p.items, hasMore: p.hasMore, currentPage: p.currentPage, isLoading: true, vocalOrder: p.vocalOrder, reqId: p.reqId, total: p.total }
        });

        var cancelToken = new CancelToken(c => {
            _cancelRequests.push(c);
        });

        // To cancel the request
        // _cancelRequest();

        var response = await openComm.GetCalls(page, type, from, to, source, destination, services, inbounds, outbounds, users, idTicket, customerCode, vocalOrderOnly, quality, qualityType, cancelToken);

        // response.error.status

        useCalls(p => {
            if (response.error !== null) {
                return { items: p.items, hasMore: false, currentPage: p.currentPage, isLoading: false, vocalOrder: p.vocalOrder, reqId: p.reqId, total: p.total }
            }
            else {
                return {
                    items: response.result.total === 0 ? [null] : [...p.items, ...response.result.media],
                    vocalOrder: response.result.vocalOrder,
                    hasMore: (p.items.length + response.result.media.length) < response.result.total,
                    currentPage: p.currentPage + 1,
                    isLoading: false,
                    reqId: p.reqId,
                    total: response.result.total
                }
            }
        });
    };

    const listener = function (e) {
        var container = document.getElementsByClassName('audio-info-container')[0];
        if (typeof container !== 'undefined') {
            if (e.deltaY > 0) container.scrollLeft += 50;
            else container.scrollLeft -= 50;
        }
    }
    const RefreshEvents = async (events) => {
        useSubItem(p => {
            return { calluid: p.calluid, audioUrl: p.audioUrl, eventItems: events, qualityOperator: p.qualityOperator, qualityVerified: p.qualityVerified, qualityNote: p.qualityNote };
        });
    };
    const OnEventClicked = async (item) => {
        let cSelected;
        let cSubItem;

        if (item.audioUrl === null && item.time === 0) {
            if (item.calluid !== subItem.calluid) {
                cSelected = item.calluid;
                cSubItem = { calluid: item.calluid, audioUrl: 'none', qualityOperator: item.qualityOperator, qualityVerified: item.qualityVerified, qualityNote: item.qualityNote };
                if (document.getElementsByClassName("collapse").length > 0) {
                    CalculateCollapsedHeight(true);
                } else {
                    CalculateHeight(null, true);
                }
            } else {
                cSelected = null;
                cSubItem = { calluid: null, audioUrl: 'none', qualityOperator: null, qualityVerified: null, qualityNote: null };
                if (document.getElementsByClassName("collapse").length > 0) {
                    CalculateCollapsedHeight(false);
                } else {
                    CalculateHeight(null, false);
                }
            }
        }
        else if (item.audioUrl !== null) {
            if (item.calluid !== subItem.calluid) {
                cSelected = item.calluid;
                cSubItem = { calluid: item.calluid, audioUrl: item.audioUrl, qualityOperator: item.qualityOperator, qualityVerified: item.qualityVerified, qualityNote: item.qualityNote };
                if (document.getElementsByClassName("collapse").length > 0) {
                    CalculateCollapsedHeight(true);
                } else {
                    CalculateHeight(null, true);
                }
            } else {
                cSelected = null;
                cSubItem = { calluid: null, audioUrl: 'none', qualityOperator: null, qualityVerified: null, qualityNote: null };
                if (document.getElementsByClassName("collapse").length > 0) {
                    CalculateCollapsedHeight(false);
                } else {
                    CalculateHeight(null, false);
                }
            }
        }
        else {
            var player = document.getElementById("player");
            player.currentTime = item.time / 1000;
        }

        useSelected(p => typeof cSelected === 'undefined' ? p : cSelected);
        useSubItem(p => typeof cSubItem !== 'undefined' ? ({ ...cSubItem, eventItems: p.eventItems }) : p);
    };

    const LoadNextPageAsync = async () => {
        // Load next page with global filters
        GetCalls(calls.currentPage, gFilters);
    };

    const OnClosePlayer = async () => {
        // Reset detailed info
        useSelected(null);
        useSubItem({ calluid: null, audioUrl: null, eventItems: [] });
        // Calculate virtualized-list height
        if (bCollapse) {
            CalculateCollapsedHeight(false);
        } else {
            CalculateHeight(null, false);
        }
    };

    const OnVerifyStarClick = async (vote) => {
        if (!subItem.qualityVerified && subItem.qualityOperator) {
            // Open vote popup
            setOverStars(vote);
            setIsOpen(true);
        }
    };

    const OnVerifyStarOver = async (vote, exist) => {
        let update;

        if (!exist) {
            update = { over: true, overStar: vote };
        }

        useSubItem(p =>
        {
            return typeof update !== 'undefined' ? ({ ...update, calluid: p.calluid, audioUrl: p.audioUrl, eventItems: p.eventItems, qualityOperator: p.qualityOperator, qualityVerified: p.qualityVerified, qualityNote: p.qualityNote }) : p;
        });
    };

    const OnVerifyStarLeave = async () => {
        useSubItem(p => {
            return { calluid: p.calluid, audioUrl: p.audioUrl, eventItems: p.eventItems, qualityOperator: p.qualityOperator, qualityVerified: p.qualityVerified, over: false, overStar: null, qualityNote: p.qualityNote };
        });
    }

    const OnCloseModal = async (e) => {
        setIsOpen(false);

        let subItemUpdate;
        let callsUpdate;

        if (e && e.confirmed) {
            var currentUid = subItem.calluid;
            var result = calls.items.filter(function (el) {
                if (el.id === currentUid) {
                    el.qualityVerified = e.stars;
                    el.qualityNote = e.note;
                }

                return el;
            });
            subItemUpdate = { qualityVerified: e.stars, over: false, overStar: null, qualityNote: e.note };
            callsUpdate = { items: result };
        }

        useSubItem(p =>
        {
            return typeof subItemUpdate !== 'undefined' ? ({ ...subItemUpdate, calluid: p.calluid, audioUrl: p.audioUrl, eventItems: p.eventItems, qualityOperator: p.qualityOperator }) : p;
        });

        useCalls(p => {
            return typeof callsUpdate !== 'undefined' ? ({ ...callsUpdate, hasMore: p.hasMore, currentPage: p.currentPage, isLoading: p.isLoading, vocalOrder: p.vocalOrder, reqId: p.reqId, total: p.total }) : p;
        });
    };

    const OnToggleCollapse = async () => {
        useCollapse(p => !p);

        // Reset detailed info
        useSelected(null);
        useSubItem({ calluid: null, audioUrl: null, eventItems: [] });

        // Calculate virtualized-list height
        if (!bCollapse) {
            CalculateCollapsedHeight(false);
        } else {
            CalculateHeight(null, false);
        }
    };

    const OnSearch = async (filters) => {
        // If we have some pending requests, cancel all
        if (_cancelRequests.length > 0) {
            for (var i = 0; i < _cancelRequests.length; i++) {
                var request = _cancelRequests[i];
                request();
            }
            _cancelRequests = [];
        }

        // Set new filters
        useFilters(filters);
        // Reset selection
        useSelected(null);
        // Reset detailed info
        useSubItem({ calluid: null, audioUrl: null, eventItems: [] });
        // Update calls to reload
        useCalls({ items: [], isLoading: false, hasMore: true, currentPage: 0, vocalOrder: filters.vocalOrderOnly, reqId: uuidv4(), total: 0 });
        // Calculate virtualized-list height
        CalculateHeight(null, false);

        // Do none if current page is zero.
        // If we cancel first request, InfiniteLoader explode
        if ((calls.currentPage === 0 && _cancelRequests.length === 0) ||
            (calls.currentPage !== 0 && calls.items !== null && calls.items.length >= 1)) {
            // Load first page with global filters
            GetCalls(0, filters);
        }
    };

    const OnFilterFormLoaded = async (form) => {
        // Save default filters as global
        useFilters(form.filters);

        // When filter form is loaded, calculate height of virtualized-list
        var formHeight = form.height;
        CalculateHeight(formHeight, false);
    };

    const CalculateHeight = async (formHeight, playerOpened, windowHeight) => {
        var navHeight = listHeight.nav || document.getElementsByTagName("nav")[0].offsetHeight;

        if (typeof windowHeight === 'undefined' || windowHeight === null) {
            windowHeight = listHeight.window || window.innerHeight;
        }

        if (typeof formHeight === 'undefined' || formHeight === null) {
            formHeight = listHeight.form;
        }

        var totHeight = windowHeight - navHeight - formHeight - 41;

        var playerInfoHeight = listHeight.player;
        if (playerOpened) {
            playerInfoHeight = typeof (document.getElementsByClassName("audio-container")[0]) === 'undefined' ? listHeight.player : document.getElementsByClassName("audio-container")[0].offsetHeight;
            totHeight -= playerInfoHeight;
        }

        if (totHeight.toString() === "NaN") {
            totHeight = 500;
        }

        // Save calculated height of virtualized-list
        useListHeight({ window: windowHeight, nav: navHeight, form: formHeight, player: playerInfoHeight, tot: totHeight });
    };
    const CalculateCollapsedHeight = async (playerOpened, windowHeight) => {
        var navHeight = listHeight.nav || document.getElementsByTagName("nav")[0].offsetHeight;

        if (typeof windowHeight === 'undefined' || windowHeight === null) {
            windowHeight = listHeight.window || window.innerHeight;
        }

        var formHeight = 0;
        var totHeight = windowHeight - navHeight - formHeight - 41;

        var playerInfoHeight = listHeight.player;
        if (playerOpened) {
            playerInfoHeight = typeof (document.getElementsByClassName("audio-container")[0]) === 'undefined' ? listHeight.player : document.getElementsByClassName("audio-container")[0].offsetHeight;
            totHeight -= playerInfoHeight;
        }

        if (totHeight.toString() === "NaN") {
            totHeight = 500;
        }

        // Save calculated height of virtualized-list
        useListHeight(p => ({ window: windowHeight, nav: navHeight, form: p.form, player: playerInfoHeight, tot: totHeight }));
    };
    const OnWindowResize = async () => {
        // Reset detailed info
        useSelected(null);
        useSubItem({ calluid: null, audioUrl: null, eventItems: [] });

        // Calculate virtualized-list height
        if (document.getElementsByClassName("collapse").length > 0) {
            CalculateCollapsedHeight(false);
        } else {
            var formHeight = document.getElementsByTagName("form")[0].offsetHeight;
            CalculateHeight(formHeight, false);
        }
    };

    useEffect(function () {
        // On loading, get user privileges
        GetPrivileges();

        window.addEventListener('wheel', listener);
        window.addEventListener('resize', OnWindowResize);
        return () => window.removeEventListener('resize', OnWindowResize);
    }, [null]);

    return (
        <React.Fragment>
            <nav className="navbar navbar-light ap-navbar">
                <div className="row w-100">
                    <div className="col-6 text-left">
                        <a className="navbar-brand" href="#">
                            <img src={Logo} width="170" height="30" className="mediaservice-logo d-inline-block align-top" alt="" />
                        </a>
                    </div>
                    <div className="col-6 text-right">
                        <img src={LogoConsulcesiTech} width="75" height="30" className="d-inline-block align-top" alt="" />
                    </div>
                </div>
                <div className="row loader w-100">
                    <div className={prv.loaded ? "bar stop" : "bar"}>
                    </div>
                </div>
            </nav>
            {
                prv.loaded ?
                    prv.vocalOrder ?
                        <React.Fragment>
                            <div className="container">
                                <div id="form" className={bCollapse ? "row collapse" : "row"}>
                                    <FilterForm onSearch={OnSearch} onFilterFormLoaded={OnFilterFormLoaded} privileges={prv} logger={_logger} />
                                </div>
                                <div className="row">
                                    <div className="col-md-12 ml-10">
                                        <div className="row">
                                            <div className="col-md-10">
                                                <span>Record found: <b>{calls.total}</b></span>
                                            </div>
                                            <div className="col-md-2 collapse-icon-container" onClick={OnToggleCollapse}>
                                                <img src={Collapse} className={bCollapse ? "collapse-icon rotate180" : "collapse-icon"} data-tip data-for='tooltip-event-collapse' />
                                                <ReactTooltip id='tooltip-event-collapse' effect="solid">
                                                    {!bCollapse ? "Hide form" : "Show form"}
                                                </ ReactTooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2" style={{ height: listHeight.tot }}>
                                        <CallsList
                                            hasNextPage={calls.hasMore}
                                            isNextPageLoading={calls.isLoading}
                                            list={calls.items}
                                            requestId={calls.reqId}
                                            loadNextPage={LoadNextPageAsync}
                                            prv={prv}
                                            selected={selected}
                                            listHeight={listHeight.tot}
                                            vocalOrder={calls.vocalOrder}
                                            refreshEvents={RefreshEvents}
                                            onEventClicked={OnEventClicked}
                                            logger={_logger}
                                        />
                                    </div>
                                </div>
                                {
                                    subItem.calluid !== null ?
                                        <React.Fragment>
                                            <div className="audio-container">
                                                <div className="close-player" onClick={OnClosePlayer}>x</div>
                                                <div className="audio-info-container">
                                                    <div className="audio-info">
                                                        {subItem.eventItems}
                                                    </div>
                                                </div>
                                                {
                                                    prv.play ?
                                                        <div className="row">
                                                            <audio className="col-md-11 p-0" id="player" src={subItem.audioUrl} controls controlsList="nodownload" />
                                                            <div className="col-md-1 p-0 stars-event-container">
                                                                <div className="star-items">
                                                                    <div>
                                                                        <img src={subItem.qualityOperator > 0 ? Star : StarEmpty} className="media-star-icon" />
                                                                        <img src={subItem.qualityOperator > 1 ? Star : StarEmpty} className="media-star-icon" />
                                                                        <img src={subItem.qualityOperator > 2 ? Star : StarEmpty} className="media-star-icon" />
                                                                        <img src={subItem.qualityOperator > 3 ? Star : StarEmpty} className="media-star-icon" />
                                                                    </div>
                                                                    <div>
                                                                        <img src={CallCenter} className="media-star-icon" />
                                                                    </div>
                                                                </div>
                                                                <div data-tip data-for={`tooltip-event-${subItem.calluid}`} className="star-items">
                                                                    {
                                                                        prv.full ?
                                                                            <div>
                                                                                <img src={(subItem.over && subItem.overStar > 0) ? StarVerified : subItem.qualityVerified > 0 ? StarVerified : StarEmpty} onMouseLeave={() => OnVerifyStarLeave()} onMouseOver={() => OnVerifyStarOver(1, (subItem.qualityVerified !== null || subItem.qualityOperator === null))} onClick={() => OnVerifyStarClick(1)} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 1) ? StarVerified : subItem.qualityVerified > 1 ? StarVerified : StarEmpty} onMouseLeave={() => OnVerifyStarLeave()} onMouseOver={() => OnVerifyStarOver(2, (subItem.qualityVerified !== null || subItem.qualityOperator === null))} onClick={() => OnVerifyStarClick(2)} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 2) ? StarVerified : subItem.qualityVerified > 2 ? StarVerified : StarEmpty} onMouseLeave={() => OnVerifyStarLeave()} onMouseOver={() => OnVerifyStarOver(3, (subItem.qualityVerified !== null || subItem.qualityOperator === null))} onClick={() => OnVerifyStarClick(3)} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 3) ? StarVerified : subItem.qualityVerified > 3 ? StarVerified : StarEmpty} onMouseLeave={() => OnVerifyStarLeave()} onMouseOver={() => OnVerifyStarOver(4, (subItem.qualityVerified !== null || subItem.qualityOperator === null))} onClick={() => OnVerifyStarClick(4)} className="media-star-icon" />
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <img src={(subItem.over && subItem.overStar > 0) ? StarVerified : subItem.qualityVerified > 0 ? StarVerified : StarEmpty} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 1) ? StarVerified : subItem.qualityVerified > 1 ? StarVerified : StarEmpty} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 2) ? StarVerified : subItem.qualityVerified > 2 ? StarVerified : StarEmpty} className="media-star-icon" />
                                                                                <img src={(subItem.over && subItem.overStar > 3) ? StarVerified : subItem.qualityVerified > 3 ? StarVerified : StarEmpty} className="media-star-icon" />
                                                                            </div>
                                                                    }
                                                                    <div>
                                                                        <img src={Supervisor} className="media-star-icon" />
                                                                    </div>
                                                                </div>
                                                                <div className={(!subItem.over && subItem.qualityVerified !== null) ? null : 'hidden'}>
                                                                    <ReactTooltip className="tooltip-event" id={`tooltip-event-${subItem.calluid}`} effect="solid">
                                                                        {typeof subItem.qualityNote !== 'undefined' && subItem.qualityNote !== null && subItem.qualityNote !== '' ? subItem.qualityNote : "Nessuna nota disponibile."}
                                                                    </ ReactTooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <React.Fragment />
                                                }
                                                
                                            </div>
                                            <VerifyStarModal calluid={subItem.calluid} qualityOperator={subItem.qualityOperator} qualityVerified={overStars} qualityNote={subItem.qualityNote} modalIsOpen={modalIsOpen} closeModal={OnCloseModal} />
                                        </React.Fragment>
                                        : <React.Fragment />
                                }
                            </div>
                        </ React.Fragment>
                        :
                        <React.Fragment>
                            <div className="error-container mt-5">
                                <div className="mb-4"><img src={ErrorIcon} width="90" /></div>
                                <span>
                                    <h4 className="text-danger">You are not authorized to view this page.</h4>
                                </span>
                            </div>
                        </ React.Fragment>
                    :
                    <React.Fragment />
            }
        </React.Fragment>
    )
}

export default Home;