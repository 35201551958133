import React, { useEffect, useState } from 'react';
import { CancelToken } from 'axios';
import DatePicker from 'react-datepicker'
import Select from 'react-select';
import Checkbox from 'rc-checkbox';
import Star from './../images/star.svg';
import StarQuality from './../images/star-quality.svg';
import StarEmpty from './../images/star-empty.svg';
import CallCenter from './../images/ic_callcenter.svg';
import Supervisor from './../images/ic_supervisor.svg';
import * as openComm from './../services/openCommService';

import './FilterForm.css';

let _logger;

function FilterForm(props) {
    _logger = props.logger;

    // Default value of 'serviceType' is NEW (1), others: NONE (0), OLD (2)
    // Default value of 'environmentType' is DYN (1), others: NONE (0), ITSM (2)
    const [serviceType, useServiceType] = useState(1);
    const [environmentType, useEnvironmentType] = useState(1);
    const [services, useServices] = useState([]);
    const [inbounds, useInbounds] = useState([]);
    const [outbounds, useOutbounds] = useState([]);
    const [users, useUsers] = useState([]);
    const [searchForm, useSearchForm] = useState({
        type: null,
        from: null,
        to: null,
        source: null,
        destination: null,
        services: [],
        inbounds: [],
        outbounds: [],
        users: [],
        idTicket: null,
        customerCode: null,
        vocalOrderOnly: props.privileges.full ? false : true,
        quality: null
    });
    const qualities = [
        { label: '1', value: 1, realValue: 1, type: 1 },
        { label: '2', value: 2, realValue: 2, type: 1 },
        { label: '3', value: 3, realValue: 3, type: 1 },
        { label: '4', value: 4, realValue: 4, type: 1 },
        { label: '1', value: 5, realValue: 1, type: 2 },
        { label: '2', value: 6, realValue: 2, type: 2 },
        { label: '3', value: 7, realValue: 3, type: 2 },
        { label: '4', value: 8, realValue: 4, type: 2 },
        { label: '>', value: 9, realValue: 5, type: 3 },
        { label: '<', value: 10, realValue: 6, type: 4 }
    ];

    const formatQualityOption = ({ value, label, type, realValue, customAbbreviation }) => {
        
        if (type == 3) {
            return (
                <div className="star-select-item">
                    <img key={i} src={CallCenter} width="20" height="20" className="d-inline-block" />
                    <span className="star-mm">&nbsp;&nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <img key={i} src={Supervisor} width="20" height="20" className="d-inline-block" />
                </div>
            )
        }
        else if (type == 4) {
            return (
                <div className="star-select-item">
                    <img key={i} src={CallCenter} width="20" height="20" className="d-inline-block" />
                    <span className="star-mm">&nbsp;&nbsp;&nbsp;&nbsp;&lt;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <img key={i} src={Supervisor} width="20" height="20" className="d-inline-block" />
                </div>
            )
        }
        else {
            var stars = [];
            for (var i = 0; i < 4; i++) {
                if (type == 1) {
                    stars.push(<img key={i} src={i < realValue ? Star : StarEmpty} width="20" height="20" className="d-inline-block" />);
                }
                if (type == 2) {
                    stars.push(<img key={i} src={i < realValue ? StarQuality : StarEmpty} width="20" height="20" className="d-inline-block" />);
                }
            }

            if (type == 1) {
                
            }
            return (
                <div className="star-select-item">
                    <div>
                        {stars}
                    </div>
                    <div className="icon-type">
                        {
                            type == 1 ?
                                <img key={i} src={CallCenter} width="20" height="20" className="d-inline-block" />
                                : type == 2 ?
                                    <img key={i} src={Supervisor} width="20" height="20" className="d-inline-block" />
                                    :
                                    <React.Fragment />
                        }
                    </div>
                </div>
            )
        }
    }

    const GetServices = async (type) => {
        let cancel;
        var cancelToken = new CancelToken(c => {
            cancel = c;
        });

        // To cancel the request
        // cancel();

        var response = await openComm.GetServices(type, cancelToken);
        useServices(response.error !== null ? [] : response.result.services);
    };
    const GetUsers = async (type) => {
        let cancel;
        var cancelToken = new CancelToken(c => {
            cancel = c;
        });

        // To cancel the request
        // cancel();

        var response = await openComm.GetUsers(type, cancelToken);
        useUsers(response.error !== null ? [] : response.result.users);
    };

    const HandleTypeChange = async (e) => {
        var resetSource = false;
        var resetDestination = false;
        if (searchForm.idTicket !== null || searchForm.customerCode !== null) {
            if (e !== null && e.value === 'inbound') {
                resetDestination = true;
            }
            if (e !== null && e.value === 'outbound') {
                resetSource = true;
            }
        }
        useSearchForm(p => ({ ...p, type: e === null ? null : e, source: resetSource ? null : p.source, destination: resetDestination ? null : p.destination }));
    };
    const HandleFromChange = async (date) => {
        if (date.getHours() < 7) {
            date.setHours(7, 0, 0, 0);
        }
        if (date.getHours() > 21) {
            date.setHours(21, 0, 0, 0);
        }

        var newType = 1;
        // WARN: Month: 10 + 1 = November
        if (date < new Date(2019, 10, 15, 23, 59, 59, 999)) {
            // If date is less than 16/11/2019
            // Type = OLD
            newType = 2;
        }

        var newEnvType = 1;
        // WARN: Month: 10 + 1 = November
        if (date < new Date(2020, 7, 12, 23, 59, 59, 999)) {
            // If date is less than 12/08/2020
            // Type = ITSM
            newEnvType = 2;
        }

        var typeChanged = false;
        if (newType !== serviceType) {
            // Update Users and Services
            typeChanged = true;
            GetServices(newType);
            GetUsers(newType);
        }

        var endDay = new Date(date).setHours(21, 0, 59, 999);
        useSearchForm(p => ({
            ...p,
            from: date,
            to: p.to < date ? new Date(endDay) : p.to,
            services: typeChanged ? [] : p.services,
            inbounds: typeChanged ? [] : p.inbounds,
            outbounds: typeChanged ? [] : p.outbounds,
            users: typeChanged ? [] : p.users
        }));
        useServiceType(newType);
        useEnvironmentType(newEnvType);
    };
    const HandleToChange = async (date) => {
        if (date < searchForm.from) {
            date = searchForm.from;
        }

        useSearchForm(p => ({ ...p, to: date }));
    };
    const HandleSourceChange = async (e) => {
        var val = e.target.value;
        useSearchForm(p => ({ ...p, source: val }));
    };
    const HandleDestinationChange = async (e) => {
        var val = e.target.value;
        useSearchForm(p => ({ ...p, destination: val }));
    };
    const HandleIdTicketChange = async (e) => {
        var val = e.target.value;
        var resetSource = false;
        var resetDestination = false;
        if ((val !== null || searchForm.customerCode !== null) && (searchForm.type === null || searchForm.type.value === 'outbound')) {
            resetSource = true;
        }
        if ((val !== null || searchForm.customerCode !== null) && (searchForm.type === null || searchForm.type.value === 'inbound')) {
            resetDestination = true;
        }
        useSearchForm(p => ({ ...p, services: [], inbounds: [], outbounds: [], users: [], source: resetSource ? null : p.source, destination: resetDestination ? null : p.destination, idTicket: val || null }));
    };
    const HandleCustomerCodeChange = async (e) => {
        var val = e.target.value;
        var resetSource = false;
        var resetDestination = false;
        if ((val !== null || searchForm.idTicket !== null) && (searchForm.type === null || searchForm.type.value === 'outbound')) {
            resetSource = true;
        }
        if ((val !== null || searchForm.idTicket !== null) && (searchForm.type === null || searchForm.type.value === 'inbound')) {
            resetDestination = true;
        }
        useSearchForm(p => ({ ...p, services: [], inbounds: [], outbounds: [], users: [], source: resetSource ? null : p.source, destination: resetDestination ? null : p.destination, customerCode: val || null }));
    };
    const HandleServiceChange = async (e) => {
        var servs = [];
        var ins = [];
        var outs = [];
        var inboundSelect = [];
        var outboundSelect = [];
        if (e !== null) {
            for (var i = 0; i < e.length; i++) {
                var serv = e[i];
                var filteredServices = services.filter(item => item.ID === serv.value)[0];

                for (var inboundProp in filteredServices.inbounds) {
                    var item = filteredServices.inbounds[inboundProp];
                    inboundSelect.push({ label: item.Name, value: item.ID });
                }

                for (var outboundProp in filteredServices.outbounds) {
                    var item = filteredServices.outbounds[outboundProp];
                    outboundSelect.push({ label: item.Name, value: item.ID });
                }

                servs.push(serv);
            }
        }

        ins = searchForm.inbounds.filter(item => inboundSelect.map(k => k.value).includes(item.value));
        outs = searchForm.outbounds.filter(item => outboundSelect.map(k => k.value).includes(item.value));

        useInbounds(inboundSelect);
        useOutbounds(outboundSelect);

        useSearchForm(p => ({ ...p, services: servs, inbounds: ins, outbounds: outs }));
    };
    const HandleInboundChange = async (e) => {
        var ins = [];
        if (e !== null) {
            ins = e;
        }

        useSearchForm(p => ({ ...p, inbounds: ins }));
    }
    const HandleOutboundChange = async (e) => {
        var outs = [];
        if (e !== null) {
            outs = e;
        }

        useSearchForm(p => ({ ...p, outbounds: outs }));
    }
    const HandleQualityChange = async (e) => {
        var quality = null;
        if (e !== null) {
            quality = e;
        }

        useSearchForm(p => ({ ...p, quality: quality }));
    }
    const HandleUserChange = async (e) => {
        var selectedUsers = [];
        if (e !== null) {
            selectedUsers = e;
        }
        useSearchForm(p => ({ ...p, services: [], users: selectedUsers }));
    };
    const HandleVocalOrderOnlyChange = async (e) => {
        useSearchForm(p => {
            if (props.privileges.full) {
                var val = e.target.checked;
                return { ...p, idTicket: null, customerCode: null, users: [], vocalOrderOnly: val };
            } else {
                return p;
            }
        });
    };

    const OnClickSearchButton = async (e) => {
        e.preventDefault();

        var form = {
            type: searchForm.type !== null ? searchForm.type.value : null,
            from: searchForm.from,
            to: searchForm.to,
            source: searchForm.source,
            destination: searchForm.destination,
            services: searchForm.services.map(item => item.value),
            inbounds: searchForm.inbounds.map(item => item.value),
            outbounds: searchForm.outbounds.map(item => item.value),
            users: searchForm.users.map(item => item.value),
            idTicket: searchForm.idTicket,
            customerCode: searchForm.customerCode,
            vocalOrderOnly: searchForm.vocalOrderOnly,
            quality: searchForm.quality !== null ? searchForm.quality.realValue : null,
            qualityType: searchForm.quality !== null ? searchForm.quality.type : null,
        }

        props.onSearch(form);
    };

    useEffect(function () {
        GetServices(serviceType);
        GetUsers(serviceType);

        props.onFilterFormLoaded({ width: document.getElementsByTagName("form")[0].offsetWidth, height: document.getElementsByTagName("form")[0].offsetHeight, filters: searchForm });
    }, [null]);

    return (

        <form className="col-md-12">
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="inFrom">From Date</label>
                    <DatePicker
                        id="inFrom"
                        className="form-control mediaservice-form-field-height"
                        popperPlacement="bottom"
                        // minDate={new Date(1573862400000)} // TODO: (da rimuovere) Fix per non far vedere la nuova modalità
                        popperModifiers={{
                            flip: {
                                behavior: ['bottom'] // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                            }
                        }}
                        selected={searchForm.from || ''}
                        onChange={HandleFromChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        minTime={new Date(new Date().setHours(7, 0, 0))}
                        maxTime={new Date(new Date().setHours(21, 0, 0))}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy HH:mm"
                    />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="inTo">To Date</label>
                    <DatePicker
                        id="inTo"
                        className="form-control mediaservice-form-field-height"
                        popperPlacement="bottom"
                        minDate={searchForm.from || new Date()}
                        popperModifiers={{
                            flip: {
                                behavior: ['bottom'] // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: true, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                            }
                        }}
                        selected={searchForm.to || ''}
                        onChange={HandleToChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        minTime={new Date(new Date().setHours(7, 0, 0))}
                        maxTime={new Date(new Date().setHours(21, 0, 0))}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy HH:mm"
                    />
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="inSource">Source Number</label>
                    <input type="text" className="form-control mediaservice-form-field-height" disabled={searchForm.quality !== null || (serviceType === 2 && searchForm.vocalOrderOnly) || ((searchForm.idTicket !== null || searchForm.customerCode !== null) && (searchForm.type === null || searchForm.type.value === 'outbound')) ? "disabled" : null} id="inSource" placeholder="Source Number" value={searchForm.source || ''} onChange={HandleSourceChange} />
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="inDestination">Destination Number</label>
                    <input type="text" className="form-control mediaservice-form-field-height" disabled={searchForm.quality !== null || (serviceType === 2 && searchForm.vocalOrderOnly) || ((searchForm.idTicket !== null || searchForm.customerCode !== null) && (searchForm.type === null || searchForm.type.value === 'inbound')) ? "disabled" : null} id="inDestination" placeholder="Destination Number" value={searchForm.destination || ''} onChange={HandleDestinationChange} />
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="qualitiesSelect">Quality</label>
                    <Select className="mediaservice-select" classNamePrefix="mediaservice-select-border" isDisabled={searchForm.vocalOrderOnly || (searchForm.source  || '') !== ''  || (searchForm.destination || '') !== '' || searchForm.idTicket !== null || searchForm.customerCode !== null || searchForm.services.length > 0 || serviceType === 2} options={qualities} id="qualitiesSelect" value={searchForm.quality} formatOptionLabel={formatQualityOption} isClearable onChange={HandleQualityChange} />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="typeSelect">Call direction</label>
                    <Select classNamePrefix="mediaservice-select-border" isDisabled={serviceType === 2 && searchForm.vocalOrderOnly} options={[{ value: "inbound", label: "INBOUND" }, { value: "outbound", label: "OUTBOUND" }]} id="typeSelect" value={searchForm.type} isClearable isSearchable onChange={HandleTypeChange} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="servicesSelect">Services</label>
                    <Select classNamePrefix="mediaservice-select-border" isDisabled={searchForm.quality !== null || searchForm.users.length > 0 || searchForm.idTicket !== null || searchForm.customerCode !== null || (serviceType === 2 && searchForm.vocalOrderOnly)} options={services.map(item => { return { value: item.ID, label: `${item.Name}` + (item.customer !== null ? `(${item.customer.Name})` : '') } })} value={searchForm.services} isMulti isSearchable id="servicesSelect" onChange={HandleServiceChange} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="inboundsSelect">Inbounds</label>
                    <Select classNamePrefix="mediaservice-select-border" options={inbounds} isDisabled={searchForm.quality !== null || searchForm.services.length === 0 || searchForm.outbounds.length !== 0 || searchForm.type === 'outbound'} isMulti isSearchable id="inboundsSelect" value={searchForm.inbounds} onChange={HandleInboundChange} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="outboundsSelect">Outbounds</label>
                    <Select classNamePrefix="mediaservice-select-border" options={outbounds} isDisabled={searchForm.quality !== null || searchForm.services.length === 0 || searchForm.inbounds.length !== 0 || searchForm.type === 'inbound'} isMulti isSearchable id="outboundsSelect" value={searchForm.outbounds} onChange={HandleOutboundChange} />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-3">
                    <label htmlFor="usersSelect">Users</label>
                    <Select classNamePrefix="mediaservice-select-border" isDisabled={searchForm.idTicket !== null || searchForm.customerCode !== null || searchForm.services.length > 0} options={users.map(item => { return { value: item.ID, label: `${item.Name} ${item.Surname}` } })} isMulti isSearchable value={searchForm.users} id="usersSelect" onChange={HandleUserChange} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="inIdTicket">ID Ticket</label>
                    <input type="text" className="form-control mediaservice-form-field-height" id="inIdTicket" placeholder="ID Ticket" disabled={searchForm.quality !== null || (serviceType === 2 && !searchForm.vocalOrderOnly) || (environmentType == 1) ? 'disabled' : null} value={searchForm.idTicket || ''} onChange={HandleIdTicketChange} />
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="inCustomerCode">Customer Code</label>
                    <input type="text" className="form-control mediaservice-form-field-height" id="inCustomerCode" placeholder="Customer Code" disabled={searchForm.quality !== null || serviceType === 2 && !searchForm.vocalOrderOnly ? 'disabled' : null} value={searchForm.customerCode || ''} onChange={HandleCustomerCodeChange} />
                </div>
                {
                    props.privileges.full ?
                        <div className="form-group col-md-2">
                            <label>&nbsp;</label>
                            <div className="form-control cb-control">
                                <Checkbox disabled={searchForm.quality !== null ? 'disabled' : null} id="cbVocalOrderOnly" checked={searchForm.vocalOrderOnly} onChange={HandleVocalOrderOnlyChange} />
                                <label className="cbVocalOrderOnlyLabel padding-left-10" htmlFor="cbVocalOrderOnly">Vocal orders only</label>
                            </div>
                        </div>
                        :
                        <div className="form-group col-md-2" />
                }
                <div className="form-group col-md-1 text-right">
                    <button style={{ 'marginTop': '25px' }} id="bnFormSearch" className="btn btn-primary" onClick={OnClickSearchButton}>SEARCH</button>
                </div>
            </div>
        </form>
    )
}

export default FilterForm;