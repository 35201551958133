import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Column, Table, InfiniteLoader, AutoSizer } from "react-virtualized";
import 'react-virtualized/styles.css';
import ListItem from './ListItem';
import './CallsList.css';

let _logger;

function CallsList(props) {
    _logger = props.logger;

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = props.hasNextPage ? props.list.length + 1 : props.list.length;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = props.isNextPageLoading ? () => { } : props.loadNextPage;

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = index => !props.hasNextPage || index < props.list.length;

    // Render an item or a loading indicator.
    const Item = (prp) => {
        var index = prp.index;
        var style = prp.style;
        let content;
        if (!isItemLoaded(index)) {
            content = <div style={style} className="col-md-12 single-list-item" key={0}><img src="https://wpamelia.com/wp-content/uploads/2018/11/ezgif-2-6d0b072c3d3f.gif" /></div>;
        } else {
            var item = props.list[index];
            if (item === null) {
                content = <div style={style} className="col-md-12 single-list-item" key={0}>{props.vocalOrder ? "No vocal-orders found." : "No calls found."}</div>;
            }
            else {

                // var calluid = `${item.calluid}-index-${index}`;
                var calluid = `${item.calluid}`;

                var classes = prp.className.split(" ");
                if (props.selected === calluid) {
                    classes.push("selected");
                }

                content = <ListItem key={index} className={classes.join(" ")} columns={prp.columns} style={style} calluid={calluid} prv={props.prv} vocalOrder={props.vocalOrder} refreshEvents={props.refreshEvents} onEventClicked={props.onEventClicked} mediaStreamKv={item.mediaStreamKv} qualityOperator={item.qualityOperator} qualityVerified={item.qualityVerified} qualityNote={item.qualityNote} type={item.type} events={item.events} services={item.services} inbounds={item.inbounds} outbound={item.outbound} fileExist={item.fileExist} mediaFullKv={item.mediaFullKv} mediaVocalOrderKvs={item.mediaVocalOrderKvs} idTicket={item.idTicket} customerCode={item.customerCode} registroCti={item.registroCti} contattoId={item.contattoId} from={item.from} to={item.to} endtime={item.endTime} starttime={item.startTime} logger={_logger} />;
            }
        }

        return content;
    };

    return (
        props.listHeight === -1 ?
            <React.Fragment />
            :
            <React.Fragment>
                <InfiniteLoader
                    isRowLoaded={isItemLoaded}
                    loadMoreRows={loadMoreItems}
                    rowCount={itemCount}
                >
                    {({ onRowsRendered, ref }) => (
                        <AutoSizer>
                            {({ width }) => (
                                <Table
                                    className={`calls-list ${props.requestId}`}
                                    height={props.listHeight}
                                    width={width}
                                    headerHeight={41}
                                    rowHeight={41}
                                    rowCount={itemCount}
                                    rowGetter={({ index }) => {
                                        return (!!props.list[index]) ? props.list[index] : {};
                                    }}
                                    onRowsRendered={onRowsRendered}
                                    rowRenderer={Item}
                                    ref={ref}
                                >
                                    <Column dataKey="00" label="Date" width={65} flexGrow={1} flexShrink={0} />
                                    <Column dataKey="01" label="Start time" width={70} flexGrow={1} flexShrink={0} />
                                    <Column dataKey="02" label="End time" width={70} flexGrow={1} flexShrink={0} />
                                    <Column dataKey="03" label="Type" width={30} flexGrow={1} flexShrink={0} />
                                    <Column dataKey="04" label="Service" width={200} flexGrow={1} flexShrink={0} />
                                    <Column dataKey="05" label="Ticket" width={75} flexGrow={1} flexShrink={0} />
                                    <Column dataKey="06" label="Customer" width={75} flexGrow={1} flexShrink={0} />
                                    <Column dataKey="07" label="Source" width={100} flexGrow={1} flexShrink={0} />
                                    <Column dataKey="08" label="Destination" width={100} flexGrow={1} flexShrink={0} />
                                    <Column dataKey="09" label="Quality" width={60} flexGrow={1} flexShrink={0} />
                                    <Column dataKey="10" label="" width={30} flexGrow={1} flexShrink={0} />
                                    {
                                        props.prv.full && props.prv.download ? <Column dataKey="11" label="Full" width={30} flexGrow={1} flexShrink={0} /> : null
                                    }
                                    {
                                        props.prv.vocalOrder && props.prv.download ? <Column dataKey="12" label="VO" width={30} flexGrow={1} flexShrink={0} /> : null
                                    }
                                </Table>
                            )}
                        </AutoSizer>
                    )}
                </InfiniteLoader>
            </React.Fragment>
    )
}

export default CallsList;